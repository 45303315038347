import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PageWrapper from "../PageWrapper";
import { Grid, Typography } from "@material-ui/core";
import image from "../../assets/png/systemsterowania.png";
import unia from "../../assets/jpeg/unia/unia-logo.jpg";
import unia2 from "../../assets/jpeg/unia/unia-logo2.jpg";
import color from "../../assets/styles/colors";
import CountUp from "react-countup";
import uniaBaner from "../../assets/jpeg/baners/banerUnion.jpg";
// import uniaBaner from "../../assets/png/unia2.png";

import translationsSwitch from "../../translations";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: theme.spacing(2),
  },
  normalText: {
    fontSize: "16px",
    "&>i>b": {
      fontSize: "26px",
      marginTop: "40px",
      marginBottom: "40px",
    },
  },
  container: {
    padding: "20px",
  },
  image: {
    width: "100%",
    height: "auto",
  },
  headerText: {
    color: color.green3,
    marginTop: "20px",
    "&>b": {
      // color: color.black,
    },
  },
  hr: {
    color: color.green1,
  },
  hashtag: {
    color: color.green1,
    fontWeight: "bold",
    fontSize: "18px",
    cursor: "pointer",
    "&:hover": {
      color: color.green3,
    },
  },
}));

const EuropeanUnion = () => {
  const classes = useStyles();
  const lang = useParams().lang.toUpperCase();
  const data = translationsSwitch("europeanUnion", lang);
  // console.log(data);
  return (
    <PageWrapper
      // notDisplayBaner
      banerImage={uniaBaner}
      title={data[0].text}
      naviChild1={data[9].text}
      naviChild2={data[0].text}
    >
      <Grid className={classes.container} xs={12} sm={11} md={10} lg={12} item>
        <hr className={classes.hr} />
        <img className={classes.image} src={unia2} alt="system sterowania" />
        <Typography
          align="center"
          style={{ fontSize: "20px" }}
          className={classes.normalText}
        >
          Projekt realizowany w ramach Działania 1.2 Infrastruktura wspomagająca
          rozwój technologiczny przedsiębiorstw Priorytetu I Badania naukowe i
          innowacje Programu Fundusze Europejskie dla Lubelskiego 2021-2027
          <br />
          <i>
            <b>
              „Wzmocnienie infrastruktury B+R służące rozwojowi zdolności
              badawczych i innowacyjnych przedsiębiorstw”
            </b>
          </i>
        </Typography>
        <Typography className={classes.headerText} variant="h5">
          Realizowane zadanie:
        </Typography>
        <Typography>Zakup infrastruktury badawczej</Typography>
        <Typography className={classes.headerText} variant="h5">
          Grupy docelowe:
        </Typography>
        <Typography>
          Wyniki prac badawczych, planowanych do realizacji w oparciu o
          zakupione odpowiednie wyposażenie laboratorium B+R, pozwolą uruchomić
          własną produkcję ELTEM na potrzeby producentów maszyn i urządzeń
          rolniczych. Bezpośrednimi odbiorcami wsparcia są pracownicy ELTEM sp.
          z o.o. i firmy AUTO-STOMA (Partner Projektu), którzy zyskują możliwość
          realizacji swoich planów i aspiracji zawodowych.
        </Typography>
        <Typography className={classes.headerText} variant="h5">
          Cel projektu:
        </Typography>
        <Typography>
          Projekt służy rozwojowi zdolności badawczych i innowacyjnych ELTEM sp.
          z o.o i AUTO-STOMA.{" "}
        </Typography>
        <Typography className={classes.headerText} variant="h5">
          Rezultaty projektu:
        </Typography>
        <Typography>
          W oparciu o zakupioną infrastrukturę badawczą będą realizowane
          projekty B+R ukierunkowane na wykorzystanie mechatroniki, w tym
          Internetu Rzeczy i sztucznej inteligencji, czyli wpisujące się w
          obszar „Cyfrowe społeczeństwo” – stanowiący jedną ze specjalizacji
          województwa lubelskiego. Wyniki prac badawczych pozwolą uruchomić
          produkcję systemów sterowania, które znajdą zastosowanie w
          nowoczesnych maszynach i urządzeniach rolniczych, odpowiadających
          wymaganiom rolnictwa 4.0. „Innowacyjne technologie i maszyny dla
          rolnictwa, w tym precyzyjnego” to zagadnienie należące do „Żywności
          wysokiej jakości” - kolejnej inteligentnej specjalizacji w regionie.
          Projekt przyczynia się do popularyzacji i wzrostu dostępności
          technologii rolnictwa inteligentnego, co w dłuższej perspektywie
          wpłynie pozytywnie na jakość życia na wsi i strukturę społeczno –
          gospodarczą województwa lubelskiego.
        </Typography>
        <br />
        <Typography className={classes.hashtag}>
          #FunduszeEU #FunduszeEuropejskie
        </Typography>

        <Typography
          style={{ textAlign: "center" }}
          className={classes.headerText}
          variant="h5"
        >
          Wartość projektu: <br />
          <b>
            {
              <CountUp
                start={0}
                end={6704540.34}
                duration={3}
                separator=" "
                decimals={2}
                decimal=","
                suffix=" PLN"
              />
            }
          </b>
        </Typography>
        <br />

        <Typography
          className={classes.headerText}
          style={{ textAlign: "center" }}
          variant="h5"
        >
          Wysokość wkładu z Funduszy Europejskich:
          <br />
          <b>
            {
              <CountUp
                start={0}
                end={2208687.09}
                duration={3}
                separator=" "
                decimals={2}
                decimal=","
                suffix=" PLN"
              />
            }
          </b>
        </Typography>
      </Grid>
      <hr className={classes.hr} />
      <Grid className={classes.container} xs={12} sm={11} md={10} lg={12} item>
        <img className={classes.image} src={unia} alt="system sterowania" />
        <Typography
          style={{ fontSize: "20px" }}
          align="center"
          className={classes.normalText}
        >
          {data[1].text}
          <br />
          <i>
            <b>{data[2].text}</b>
          </i>
          <br />
          <br />
        </Typography>
        <Typography className={classes.headerText} variant="h5">
          {data[3].text}
        </Typography>
        <Typography>{data[4].text}</Typography>
        <Typography className={classes.headerText} variant="h5">
          {data[5].text}
        </Typography>
        <Typography>{data[6].text}</Typography>
        <br />
        <Typography
          style={{ textAlign: "center" }}
          className={classes.headerText}
          variant="h5"
        >
          {data[7].text} <br />
          <b>
            {
              <CountUp
                start={0}
                end={4321155.41}
                duration={3}
                separator=" "
                decimals={2}
                decimal=","
                suffix=" PLN"
              />
            }
          </b>
        </Typography>
        <br />

        <Typography
          style={{ textAlign: "center" }}
          className={classes.headerText}
          variant="h5"
        >
          {data[8].text}
          <br />
          <b>
            {
              <CountUp
                start={0}
                end={2107880.55}
                duration={3}
                separator=" "
                decimals={2}
                decimal=","
                suffix=" PLN"
              />
            }
          </b>
        </Typography>
        <Grid item>
          <center>
            <img
              className={classes.image}
              src={image}
              alt="system sterowania"
            />
          </center>
        </Grid>
      </Grid>
    </PageWrapper>
  );
};

export default EuropeanUnion;
